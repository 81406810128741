"use strict";

/**
 * File navigation.js.
 *
 * Handles toggling the navigation menu for small screens and enables TAB key
 * navigation support for dropdown menus.
 */
document.addEventListener("DOMContentLoaded", function (e) {
  // inlineSvgs();
  navigationInit();
  rollingTextInit(); // customSliders();

  effectsInit();
  svgToObj();
});

function navigationInit() {
  var mainMenu = document.querySelector(".main-navigation");
  if (!mainMenu) return;
  var menuToggle = mainMenu.querySelector(".menu-toggle");
  var linksWithChildren = mainMenu.querySelectorAll(".menu-item-has-children");
  menuToggle.addEventListener("click", function (e) {
    document.body.classList.toggle("menu-toggled");

    if (menuToggle.getAttribute("aria-expanded") === "false") {
      menuToggle.setAttribute("aria-expanded", true);
    } else {
      menuToggle.setAttribute("aria-expanded", false);
    }
  });
  linksWithChildren.forEach(function (link, i) {
    // console.log(link);
    var dropDown = document.createElement("button");
    dropDown.className = "dropdown-toggle";
    dropDown.addEventListener("click", function (e) {
      link.classList.toggle("active");
    });
    link.prepend(dropDown);
  });
}

function rollingTextInit() {
  var rTexts = document.querySelectorAll('.is-style-rolling-text');
  rTexts.forEach(function (el, i) {
    var wrapper = el.querySelector('.wp-block-group__inner-container');
    wrapper.classList.add('rolling-wrapper');
    wrapper.innerHTML = '<div class="part">' + wrapper.innerHTML + '</div>';

    for (var index = 0; index < 3; index++) {
      wrapper.innerHTML += wrapper.innerHTML;
    }
  });
}

function customSliders() {
  if (!window.sliders) return;
  window.sliders.forEach(function (slider, i) {
    if (slider.el.parentNode.classList.contains('artists-slider')) {
      slider.on('slideChange', function (slider) {
        console.log('slide change', slider);
      });
      slider.on('toEdge', function (slider) {
        setTimeout(function () {
          slider.mousewheel.disable();
        }, 1000);
      });
      slider.update();
    }
  });
}

function effectsInit() {
  // headings
  document.querySelectorAll('.entry-content :where(h1,h2,h3,h4,h5,p,ul:not(.artists-list) li)').forEach(function (el, i) {
    gsap.from(el, {
      opacity: 0,
      scale: .95,
      scrollTrigger: {
        trigger: el
      }
    });
  }); // artist lineout

  document.querySelectorAll('.to-pin').forEach(function (el, i) {
    var queryBlocks = el.querySelectorAll('.custom-query-block');
    var bg = el.querySelector('.wp-block-cover__image-background');
    var entries = el.querySelectorAll('.hentry');
    var tl = gsap.timeline();

    if (window.outerWidth > window.outerHeight) {
      // entries.forEach((el, i) => {
      // 	gsap.set(el, {
      // 		boxShadow: `10px 10px 0 rgba(0,0,0,.125)`,
      // 		borderRadius: 'var(--border-radius)',
      // 	})
      // })
      queryBlocks.forEach(function (qb, i) {
        gsap.set(qb, {
          position: 'absolute',
          top: '50%',
          y: '-50%',
          margin: 0,
          padding: 0,
          alignItems: 'center',
          minHeight: 0,
          width: '500%',
          zIndex: queryBlocks.length - i
        });
        var container = qb.querySelector('.query-container');
        var delay = i > 0 ? '<-=.3' : '';
        tl.set(qb, {
          zIndex: queryBlocks.length - i
        });
        tl.from(container, {
          opacity: 0,
          scale: .9,
          duration: .1
        }, delay); // entries.forEach((el, i) => {
        // 	tl.to(el, {
        // 		boxShadow: `30px 30px 10px rgba(0,0,0,.125)`,
        // 	}, '<');
        // })

        tl.addLabel("label-".concat(i), '>');
        if (i == queryBlocks.length - 1) return;
        tl.set(container, {
          gap: '36px'
        });
        tl.to(container, {
          gap: '300px',
          duration: .75,
          scale: 1.1
        }, '<+=.1');
        tl.set(qb, {
          zIndex: -1
        }, '<+=.3');
        tl.to(container, {
          opacity: 0,
          duration: .1,
          scale: 1.2
        }, '-=.3');
      });
      gsap.set(el, {
        height: '100vh',
        alignItems: 'start'
      });
      ScrollTrigger.create({
        trigger: el,
        pin: true,
        end: "".concat(el.clientHeight * queryBlocks.length, "px"),
        animation: tl,
        scrub: .7 // snap: {
        // 	snapTo: "labels",
        // 	// dalay: 0.0001,
        // }

      });
    } else {
      // if is vertival....
      gsap.set(bg, {
        height: '100vh'
      });
      gsap.to(bg, {
        scrollTrigger: {
          trigger: bg,
          pin: true,
          end: "+=".concat(el.clientHeight, "px")
        }
      }); // console.log(el.clientHeight);

      entries.forEach(function (el, i) {
        var translate = i % 2 ? '100%' : '-100%';
        gsap.set(el, {
          transition: 'none'
        });
        gsap.from(el, {
          opacity: 0,
          x: translate,
          scrollTrigger: {
            trigger: el,
            start: 'top 90%',
            end: 'top 60%',
            scrub: 1
          }
        });
      });
    }
  }); // document.querySelectorAll('.to-pin .custom-query-block:not(:last-child)').forEach((el, i) => {
  // 	const entries = el.querySelectorAll('.hentry');
  // 	entries.forEach((el, i) => {
  // 		gsap.to(el, {
  // 			opacity: 0,
  // 			scrollTrigger: {
  // 				trigger: el,
  // 				start: 'top 10%',
  // 				scrub: .6,
  // 			},
  // 		});
  // 	})
  // })
  // document.querySelectorAll('.to-pin .custom-query-block').forEach((el, i) => {
  // 	const container = el.querySelector('.query-container');
  // 	if (window.innerWidth > window.innerHeight) {
  // 		// gsap.set(container, { justifyContent: 'space-between' });
  // 		gsap.set(el, {
  // 			width: '1000%',
  // 		});
  // 		gsap.from(container, {
  // 			gap: '100em',
  // 			scrollTrigger: {
  // 				trigger: el,
  // 				start: 'top 70%',
  // 				end: 'top 30%',
  // 				scrub: 1,
  // 			},
  // 		});
  // 		gsap.from(container.children, {
  // 			opacity: 0,
  // 			scrollTrigger: {
  // 				trigger: el,
  // 				start: 'top 70%',
  // 				end: 'top 30%',
  // 				scrub: 1,
  // 			},
  // 		});
  // 	} else {
  // 		const entries = el.querySelectorAll('.hentry');
  // 		entries.forEach((el, i) => {
  // 			const translate = (i % 2) ? '100%' : '-100%';
  // 			gsap.set(el, { transition: 'none' });
  // 			gsap.from(el, {
  // 				opacity: 0,
  // 				x: translate,
  // 				scrollTrigger: {
  // 					trigger: el,
  // 					start: 'top 90%',
  // 					end: 'top 60%',
  // 					scrub: 1,
  // 				},
  // 			});
  // 		})
  // 	}
  // })
  // gallery effect

  document.querySelectorAll('.wp-block-gallery .wp-block-image').forEach(function (el, i) {
    gsap.set(el, {
      opacity: 0,
      scale: .98
    });
  });
  ScrollTrigger.batch('.wp-block-gallery .wp-block-image', {
    onEnter: function onEnter(elements) {
      console.log('enter', elements);
      gsap.to(elements, {
        scale: 1,
        // y: 0,
        opacity: 1,
        stagger: 0.1
      });
    }
  }); // artist list

  document.querySelectorAll('.artists-list li').forEach(function (el, i) {
    // gsap.from(el, {
    // 	opacity: 0,
    // 	scale: .95,
    // 	scrollTrigger: {
    // 		trigger: el,
    // 		// start: 'top 20%',
    // 		// scrub: .6,
    // 	},
    // });
    // gsap.to(el, {
    // 	opacity: 0,
    // 	// scale: .95,
    // 	scrollTrigger: {
    // 		trigger: el,
    // 		start: 'top 20%',
    // 		scrub: .6,
    // 	},
    // });
    gsap.from(el, {
      opacity: 0,
      // scale: .95,
      scrollTrigger: {
        trigger: el,
        start: 'top 80%',
        end: 'top 50%',
        scrub: .6
      }
    });
  });
}

function inlineSvgs() {
  document.querySelectorAll('.wp-block-cover__image-background[src*=".svg"]').forEach(function (el) {
    fetchSvgInline(el);
  });
}

function fetchSvgInline(image) {
  fetch(image.currentSrc || image.src).then(function (response) {
    return response.text();
  }).then(function (response) {
    var svgStr = response;

    if (svgStr.indexOf('<svg') === -1) {
      return;
    }

    var span = document.createElement('span');
    span.innerHTML = svgStr;
    var inlineSvg = span.querySelector('svg');
    inlineSvg.setAttribute('aria-label', image.alt || '');
    inlineSvg.setAttribute('class', image.className); // IE doesn't support classList on SVGs

    inlineSvg.setAttribute('focusable', false);
    inlineSvg.setAttribute('role', 'img');

    if (image.height) {
      inlineSvg.setAttribute('height', image.height);
    }

    if (image.width) {
      inlineSvg.setAttribute('width', image.width);
    }

    image.parentNode.replaceChild(inlineSvg, image);
  }).catch(function () {
    image.classList.add('not-inline');
  });
}

;

function svgToObj() {
  if (!isSafari()) return;
  document.querySelectorAll('.wp-block-cover__image-background[src*=".svg"]').forEach(function (el) {
    var obj = document.createElement('object');
    obj.type = "image/svg+xml";
    obj.data = el.src;
    obj.className = el.className;
    el.parentNode.appendChild(obj);
    el.remove();
  });
}

var isSafari = function isSafari() {
  return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && navigator.userAgent.indexOf('CriOS') == -1 && navigator.userAgent.indexOf('FxiOS') == -1;
};